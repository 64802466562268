import {useEffect, useState} from "react";
import {approvalStateToText, convertIsoToDateFormat, mapDateFormat, parseJwt} from "../utils/utils";
import {
    GovFormControl,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import PieChartCustomPercent from "../dashboardComponents/PieChartCustomPercent";
import HorizontalBarChartCustomForPhases from "../dashboardComponents/HorizontalBarChartCustomForPhases";
import HorizontalBarChartCustomConsultations from "../dashboardComponents/HorizontalBarChartCustomConsultations";

export function DashboardKPI() {

    const [contactedTowns, setContactedTowns] = useState(0);
    const [entryConsultationsTowns, setEntryConsultationsTowns] = useState(0);
    const [liveProjects, setLiveProjects] = useState(0);
    const [finishedPhases, setFinishedPhases] = useState(0);
    const [flatsFinanced, setFlatsFinanced] = useState(0);
    const [projectPipeline, setProjectPipeline] = useState(0);
    const [projectsInvestment, setProjectsInvestment] = useState(0);
    const [satisfaction, setSatisfaction] = useState(0);

    const [contactedTownsExpected, setContactedTownsExpected] = useState(0);
    const [entryConsultationsTownsExpected, setEntryConsultationsTownsExpected] = useState(0);
    const [liveProjectsExpected, setLiveProjectsExpected] = useState(0);
    const [finishedPhasesExpected, setFinishedPhasesExpected] = useState(0);
    const [flatsFinancedExpected, setFlatsFinancedExpected] = useState(0);
    const [projectPipelineExpected, setProjectPipelineExpected] = useState(0);
    const [projectsInvestmentExpected, setProjectsInvestmentExpected] = useState(0);
    const [satisfactionExpected, setSatisfactionExpected] = useState(0);

    const [year, setYear] = useState(0);
    const [data, setData] = useState(null);

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");
    const jwt = localStorage.getItem("jwt")

    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [regionalCenter, setRegionalCenter] = useState(() => {
        return authority === "HEADQUARTERS" ? "0" : RCid;
    });

    const handleRegionalCenterChange = (event) => {
        setRegionalCenter(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const regionMap = {
        1: "RC1", // Moravskoslezský kraj
        2: "RC2", // Středočeský kraj a Praha
        3: "RC3", // Ústecký a Karlovarský kraj
        4: "RC4", // Královehradecký a Liberecký kraj
        5: "RC5", // Pardubický kraj a kraj Vysočina
        6: "RC6", // Jihočeský kraj a Plzeňský kraj
        7: "RC7", // Zlínský a Jihomoravský kraj
        8: "RC8"  // Olomoucký kraj
    };

    const yearTranslation = (year) => {
        // eslint-disable-next-line default-case
        switch (year) {
            case "1":
                return "2024 H1"
            case "2":
                return "2024 H2"
            case "3":
                return "2025 H1"
            case "4":
                return "2025 H2"
            case "5":
                return "2026 H1"
            case "6":
                return "2026 H2"
            default:
                return 0
        }
    }

    const RC_KPIs = {
        RC1: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 48, "2024 H2": 48,
                "2025 H1": 69.5, "2025 H2": 69.5,
                "2026 H1": 32.5, "2026 H2": 32.5
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 20, "2025 H2": 20,
                "2026 H1": 10, "2026 H2": 10
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 6, "2024 H2": 6,
                "2025 H1": 5, "2025 H2": 5,
                "2026 H1": 4, "2026 H2": 4
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 12.5, "2024 H2": 12.5,
                "2025 H1": 17.5, "2025 H2": 17.5,
                "2026 H1": 10, "2026 H2": 10
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 20, "2025 H2": 20,
                "2026 H1": 33, "2026 H2": 33
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 170, "2026 H2": 170
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 189.5, "2026 H2": 189.5
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC2: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 250, "2024 H2": 250,
                "2025 H1": 250, "2025 H2": 250,
                "2026 H1": 100.5, "2026 H2": 100.5
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 60, "2024 H2": 60,
                "2025 H1": 60, "2025 H2": 60,
                "2026 H1": 30.5, "2026 H2": 30.5
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 20, "2025 H2": 20,
                "2026 H1": 3, "2026 H2": 3
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 22.5, "2024 H2": 22.5,
                "2025 H1": 27.5, "2025 H2": 27.5,
                "2026 H1": 5, "2026 H2": 5
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 10, "2024 H2": 10,
                "2025 H1": 30, "2025 H2": 30,
                "2026 H1": 22.5, "2026 H2": 22.5
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 156.5, "2026 H2": 156.5
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 174.5, "2026 H2": 174.5
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC3: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 100, "2024 H2": 100,
                "2025 H1": 100, "2025 H2": 100,
                "2026 H1": 43, "2026 H2": 43
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 25, "2024 H2": 25,
                "2025 H1": 25, "2025 H2": 25,
                "2026 H1": 11, "2026 H2": 11
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 10, "2024 H2": 10,
                "2025 H1": 12.5, "2025 H2": 12.5,
                "2026 H1": 7.5, "2026 H2": 7.5
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 22.5, "2025 H2": 22.5,
                "2026 H1": 7.5, "2026 H2": 7.5
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 42.5, "2026 H2": 42.5
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 106.5, "2026 H2": 106.5
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 116.5, "2026 H2": 116.5
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC4: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 200, "2024 H2": 200,
                "2025 H1": 131.5, "2025 H2": 131.5,
                "2026 H1": 0, "2026 H2": 0
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 75/2, "2024 H2": 75/2,
                "2025 H1": 95/2, "2025 H2": 95/2,
                "2026 H1": 63/2, "2026 H2": 63/2
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 10, "2024 H2": 10,
                "2025 H1": 15, "2025 H2": 15,
                "2026 H1": 5, "2026 H2": 5
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 20, "2024 H2": 20,
                "2025 H1": 25, "2025 H2": 25,
                "2026 H1": 10, "2026 H2": 10
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 31, "2025 H2": 31,
                "2026 H1": 20, "2026 H2": 20
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 122.5, "2026 H2": 122.5
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 142, "2026 H2": 142
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC5: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 577.5, "2024 H2": 577.5,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 0, "2026 H2": 0
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 50, "2024 H2": 50,
                "2025 H1": 50, "2025 H2": 50,
                "2026 H1": 44.5, "2026 H2": 44.5
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 15, "2025 H2": 15,
                "2026 H1": 6.5, "2026 H2": 6.5
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 10, "2024 H2": 10,
                "2025 H1": 25, "2025 H2": 25,
                "2026 H1": 15, "2026 H2": 15
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 20, "2025 H2": 20,
                "2026 H1": 34, "2026 H2": 34
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 135, "2026 H2": 135
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 150.5, "2026 H2": 150.5
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC6: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 512, "2024 H2": 512,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 0, "2026 H2": 0
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 50, "2024 H2": 50,
                "2025 H1": 50, "2025 H2": 50,
                "2026 H1": 40.5, "2026 H2": 40.5
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 12.5, "2024 H2": 12.5,
                "2025 H1": 15.5, "2025 H2": 15.5,
                "2026 H1": 7.5, "2026 H2": 7.5
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 25, "2025 H2": 25,
                "2026 H1": 17.5, "2026 H2": 17.5
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 30, "2025 H2": 30,
                "2026 H1": 41, "2026 H2": 41
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 177.5, "2026 H2": 177.5
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 198, "2026 H2": 198
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC7: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 200, "2024 H2": 200,
                "2025 H1": 250, "2025 H2": 250,
                "2026 H1": 40, "2026 H2": 40
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 55, "2024 H2": 55,
                "2025 H1": 65, "2025 H2": 65,
                "2026 H1": 51.5, "2026 H2": 51.5
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 15, "2024 H2": 15,
                "2025 H1": 22.5, "2025 H2": 22.5,
                "2026 H1": 5.5, "2026 H2": 5.5
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 20, "2024 H2": 20,
                "2025 H1": 30, "2025 H2": 30,
                "2026 H1": 15, "2026 H2": 15
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 93.5, "2025 H2": 93.5,
                "2026 H1": 8.5, "2026 H2": 8.5
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 255, "2026 H2": 255
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 284.5, "2026 H2": 284.5
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC8: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 75, "2024 H2": 75,
                "2025 H1": 100, "2025 H2": 100,
                "2026 H1": 24.5, "2026 H2": 24.5
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 20, "2024 H2": 20,
                "2025 H1": 20, "2025 H2": 20,
                "2026 H1": 10.5, "2026 H2": 10.5
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 3.5, "2024 H2": 3.5,
                "2025 H1": 5.5, "2025 H2": 5.5,
                "2026 H1": 6, "2026 H2": 6
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 10, "2024 H2": 10,
                "2025 H1": 20, "2025 H2": 20,
                "2026 H1": 10, "2026 H2": 10
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 20, "2024 H2": 20,
                "2025 H1": 25, "2025 H2": 25,
                "2026 H1": 6, "2026 H2": 6
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 122.5, "2026 H2": 122.5
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 142, "2026 H2": 142
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        },
        RC: {
            "Počet oslovených obcí": {
                "Váha": "10",
                "2024 H1": 2012.5, "2024 H2": 2012.5,
                "2025 H1": 901, "2025 H2": 901,
                "2026 H1": 240.5, "2026 H2": 240.5
            },
            "Počet obcí se vstupní schůzkou": {
                "Váha": "10",
                "2024 H1": 312.5, "2024 H2": 312.5,
                "2025 H1": 337.5, "2025 H2": 337.5,
                "2026 H1": 230, "2026 H2": 230
            },
            "Počet obcí ve spolupráci s živým projektem": {
                "Váha": "10",
                "2024 H1": 87, "2024 H2": 87,
                "2025 H1": 111, "2025 H2": 111,
                "2026 H1": 45, "2026 H2": 45
            },
            "Vážený počet dokončených fází projektu": {
                "Váha": "10",
                "2024 H1": 125, "2024 H2": 125,
                "2025 H1": 192.5, "2025 H2": 192.5,
                "2026 H1": 90, "2026 H2": 90
            },
            "Počet bytů s podanými žádostmi o financování": {
                "Váha": "20",
                "2024 H1": 45, "2024 H2": 45,
                "2025 H1": 249.5, "2025 H2": 249.5,
                "2026 H1": 207.5, "2026 H2": 207.5
            },
            "Pipeline projektů pro období po 30/6/2026": {
                "Váha": "20",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 1255, "2026 H2": 1255
            },
            "Celková výše alokace / investice v předložených projektech": {
                "Váha": "10",
                "2024 H1": 0, "2024 H2": 0,
                "2025 H1": 0, "2025 H2": 0,
                "2026 H1": 1400, "2026 H2": 1400
            },
            "Spokojenost obcí se spoluprací": {
                "Váha": "10",
                "2024 H1": 2, "2024 H2": 2,
                "2025 H1": 2, "2025 H2": 2,
                "2026 H1": 2, "2026 H2": 2
            }
        }
    }

        const fetchData = (year) => {
        let startMonth
        let endMonth
        let yearNumber = yearTranslation(year)
        if (year === '0' || year === 0) {
            startMonth = `2024-01`
            endMonth = `2026-12`
        } else if (yearNumber.toString().includes("H1")) {
            startMonth = `${yearNumber.toString().split(' ')[0]}-01`
            endMonth = `${yearNumber.toString().split(' ')[0]}-06`
        } else {
            startMonth = `${yearNumber.toString().split(' ')[0]}-07`
            endMonth = `${yearNumber.toString().split(' ')[0]}-12`
        }

        setDataIsLoading(true);
        fetch(process.env.REACT_APP_API_URL + `/dashboard/kpi?from=${startMonth}&to=${endMonth}` + ((regionalCenter !== "" && regionalCenter !== "0") ? `&rcId=${regionalCenter}` : ""), {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                console.log(process.env.REACT_APP_API_URL + `/dashboard/kpi?from=${startMonth}&to=${endMonth}` + ((regionalCenter !== "" && regionalCenter !== "0") ? `&rcId=${regionalCenter}` : ""))

                setData(jsonData)
                setDataIsLoading(false);

                setContactedTowns(jsonData.contactedTowns)
                setEntryConsultationsTowns(jsonData.townsWithPreProjectConsultation)
                setLiveProjects(jsonData.townsWithLiveProject)
                setFinishedPhases(jsonData.finishedProjectPhasePoints)
                setFlatsFinanced(jsonData.apartmentCountWithFinancingThanksToRc)
                setProjectPipeline(jsonData.projectPipeline)
                setProjectsInvestment(formatCzechNumber(jsonData.projectInvestmentAmount))
                setSatisfaction(jsonData.avgConsultationRating.toFixed(2))
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };

    function updateData() {
        if (data != null) {
            setContactedTowns(data.contactedTowns)
            setEntryConsultationsTowns(data.townsWithPreProjectConsultation)
            setLiveProjects(data.townsWithLiveProject)
            setFinishedPhases(data.finishedProjectPhasePoints)
            setFlatsFinanced(data.apartmentCountWithFinancingThanksToRc)
            setProjectPipeline(data.projectPipeline)
            setProjectsInvestment(formatCzechNumber(data.projectInvestmentAmount))
            setSatisfaction(data.avgConsultationRating.toFixed(2))
        }
    }

    function getExpectedData(selectedRegion, year, data) {
        year = yearTranslation(year)
        if (data === "Spokojenost obcí se spoluprací") {
            return 2
        }
        if (parseInt(selectedRegion) === 0) {
            if (year === 0) {
                return RC_KPIs["RC"][data]["2024 H1"] + RC_KPIs["RC"][data]["2025 H1"] + RC_KPIs["RC"][data]["2026 H1"] + RC_KPIs["RC"][data]["2024 H2"] + RC_KPIs["RC"][data]["2025 H2"] + RC_KPIs["RC"][data]["2026 H2"];
            }
            return RC_KPIs["RC"][data][year]
        } else {
            const rcKey = regionMap[parseInt(selectedRegion)];
            if (year === 0) {
               return RC_KPIs[rcKey][data]["2024 H1"] + RC_KPIs[rcKey][data]["2025 H1"] + RC_KPIs[rcKey][data]["2026 H1"] + RC_KPIs[rcKey][data]["2024 H2"] + RC_KPIs[rcKey][data]["2025 H2"] + RC_KPIs[rcKey][data]["2026 H2"];
            }
            return RC_KPIs[rcKey] ? RC_KPIs[rcKey][data][year] || 0 : 0;
        }
    }

    function formatCzechNumber(num) {
        return num
            .toFixed(2) // zaokrouhlí na dvě desetinná místa
            .replace('.', ',') // nahradí tečku čárkou
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "); // přidá mezery po trojicích
    }

    useEffect(() => {
        //updateData()
        fetchData(year)
        updateExpected()
    }, [year, regionalCenter]);

    const updateExpected = () => {
        setContactedTownsExpected(getExpectedData(regionalCenter, year,"Počet oslovených obcí"))
        setEntryConsultationsTownsExpected(getExpectedData(regionalCenter, year,"Počet obcí se vstupní schůzkou"))
        setLiveProjectsExpected(getExpectedData(regionalCenter, year,"Počet obcí ve spolupráci s živým projektem"))
        setFinishedPhasesExpected(getExpectedData(regionalCenter, year,"Vážený počet dokončených fází projektu"))
        setFlatsFinancedExpected(getExpectedData(regionalCenter, year,"Počet bytů s podanými žádostmi o financování"))
        setProjectPipelineExpected(getExpectedData(regionalCenter, year,"Pipeline projektů pro období po 30/6/2026"))
        setProjectsInvestmentExpected(getExpectedData(regionalCenter, year,"Celková výše alokace / investice v předložených projektech"))
        setSatisfactionExpected(getExpectedData(regionalCenter, year,"Spokojenost obcí se spoluprací"))
    }

    return (
        <div>
            <div className="gov-container dashboard-container padding-top-0">
                <div className="gov-container__content gov-container__content mb-200">
                    <div
                        className="gov-title--beta">KPI: {authority === 'HEADQUARTERS' ? '' : localStorage.getItem("RCname")}</div>
                    <div className="gov-flexbox gov-flexbox--justify-start">
                        {authority === 'HEADQUARTERS' &&
                            <GovFormControl>
                                <GovFormSelect size="s" placeholder="Regionální centrum"
                                               onGov-change={handleRegionalCenterChange} value={regionalCenter}>
                                    <option disabled="disabled" value="">Vyberte regionální centrum</option>
                                    <option value="0">Všechna regionální centra</option>
                                    <option value="1">Moravskoslezský kraj</option>
                                    <option value="2">Středočeský kraj a Praha</option>
                                    <option value="3">Ústecký a Karlovarský kraj</option>
                                    <option value="4">Královehradecký a Liberecký kraj</option>
                                    <option value="5">Pardubický kraj a kraj Vysočina</option>
                                    <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                    <option value="7">Zlínský a Jihomoravský kraj</option>
                                    <option value="8">Olomoucký kraj</option>
                                </GovFormSelect>
                                <GovFormLabel slot="top" size="s">Regionální centrum*</GovFormLabel>
                            </GovFormControl>
                        }
                        <GovFormControl className="ml-5">
                            <GovFormSelect size="s" placeholder="Regionální centrum"
                                           onGov-change={handleYearChange} value={year}>
                                <option disabled="disabled" value="">Vyberte rok pro který chcete zobrazit KPI</option>
                                <option value="0">2024 - 2026</option>
                                <option value="1">2024 - 1.polovina</option>
                                <option value="2">2024 - 2.polovina</option>
                                <option value="3">2025 - 1.polovina</option>
                                <option value="4">2025 - 2.polovina</option>
                                <option value="5">2026 - 1.polovina</option>
                                <option value="6">2026 - 2.polovina</option>
                            </GovFormSelect>
                            <GovFormLabel slot="top" size="s">Rok pro zobrazení KPI:</GovFormLabel>
                        </GovFormControl>
                    </div>
                    <GovSpacer size="m"></GovSpacer>
                    {!dataIsLoading ?
                        <div>
                            <table className="gov-table table-kpi">
                                <thead>
                                <tr>
                                    <th>Počet oslovených obcí</th>
                                    <th>Počet obcí se vstupní schůzkou</th>
                                    <th>Počet obcí ve spolupráci s "živým" projektem</th>
                                    <th>Vážený počet dokončených fází projektu (body)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={contactedTowns >= contactedTownsExpected ? "green" : "red"} >{contactedTowns} / {contactedTownsExpected}</td>
                                    <td className={entryConsultationsTowns >= entryConsultationsTownsExpected ? "green" : "red"}>{entryConsultationsTowns} / {entryConsultationsTownsExpected}</td>
                                    <td className={liveProjects >= liveProjectsExpected ? "green" : "red"}>{liveProjects} / {liveProjectsExpected}</td>
                                    <td className={finishedPhases <= finishedPhasesExpected ? "green" : "red"}>{finishedPhases} / {finishedPhasesExpected}</td>
                                </tr>
                                </tbody>
                            </table>
                            <table className="gov-table table-kpi">
                                <thead>
                                <tr>
                                    <th>Počet bytů s podanými žádostmi o financování díky činnosti RC</th>
                                    <th>Pipeline projektů pro období po 30/6/2026</th>
                                    <th>Celková výše alokace / investice v předložených projektech (dle žádostí), mil. Kč
                                    </th>
                                    <th>Spokojenost obcí se spoluprací</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={flatsFinanced >= flatsFinancedExpected ? "green" : "red"}>{flatsFinanced} / {flatsFinancedExpected}</td>
                                    <td className={projectPipeline >= projectPipelineExpected ? "green" : "red"}>{projectPipeline} / {projectPipelineExpected}</td>
                                    <td className={projectsInvestment >= projectPipelineExpected ? "green" : "red"}>{projectsInvestment} / {projectsInvestmentExpected}</td>
                                    <td className={satisfaction <= satisfactionExpected ? "green" : "red"}>{satisfaction} / {satisfactionExpected}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                     shape="text" animation="progress" count="1"
                                     height="700px"></GovSkeleton>}
                </div>

            </div>
        </div>
    )
}
