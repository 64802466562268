import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {ReportAuditContent} from "../components/contentComponents/ReportAuditContent";

export function ReportAudit() {
    return (
        <div>
            <Header active={"settings-active"}></Header>
            <ReportAuditContent></ReportAuditContent>
            <Footer></Footer>
        </div>
    )
}
