// import './css/mayor-home.css'
import {GovAccordion, GovAccordionItem, GovTile, GovTiles} from "@gov-design-system-ce/react";

export function HomePage() {
    const tileData = [
        { name: "Jak to funguje", text: "Prohlédněte si, jak celý proces funguje a s čím Vám můžeme pomoct.", url: "/" },
        // { name: "Registrace", text: "Založte si jednoduchý profil a vytvořte svůj první projekt", url: "registration" },
    ];
    return (

        <div>
            <div className="gov-container">
                <div className="gov-container__content">
                    <h1>První kroky</h1>
                    <GovTiles>
                        {tileData.map((tile, index) => (
                            <GovTile key={index} href={tile.url}><p>{tile.text}</p>
                                <h3 slot="title">{tile.name}</h3>
                            </GovTile>
                        ))}
                    </GovTiles>
                </div>
            </div>

            <div className="gov-container">
                <div className="gov-container__content">
                    <div className="gov-layout gov-layout--ratio-1-2">
                        <div className="gov-layout__col gov-layout__col--pos-left mb-25p"><h2
                            className="gov-title--alpha">Časté dotazy</h2></div>
                        <div className="gov-layout__col gov-layout__col--pos-right gov-layout__col--spread-2">
                            <GovAccordion variant="primary" size="xs"
                                          wcag-label="Basic information about the gov.cz design system">
                                <GovAccordionItem><p>Informace o Státním fondu podpory investic naleznete na <a
                                    className="gov-link--external" href="sfpi.cz">www.sfpi.cz</a></p><h3
                                    slot="label">Co znamená zkratka SFPI</h3></GovAccordionItem>
                                <GovAccordionItem><p>Nebojte se, chceme po vás jen osobní údaje, které jsou nezbytné pro
                                    správnou funkčnost portálu.
                                    Bližší informace o zpracování osobních údajů jsou umístěny <a
                                        className="gov-link--external" href="sfpi.cz/zpracovani-osobnich-udaju/">zde</a>
                                </p><h3
                                    slot="label">Jsou moje údaje v bezpečí?</h3></GovAccordionItem>
                            </GovAccordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}
