import Navigation from "../Navigation";
import {useState, useEffect} from "react";
import {
    GovButton,
    GovDropdown,
    GovFormCheckbox,
    GovFormInput,
    GovFormLabel,
    GovIcon, GovPagination,
    GovSkeleton, GovSpacer
} from "@gov-design-system-ce/react";
import {
    auditReportToText,
    convertIsoToCustomFormat,
    convertIsoToCustomFormatForDocuments,
    convertIsoToDateFormat
} from "../utils/utils";

export function ReportAuditContent() {
    const [auditData, setAuditData] = useState('');
    const [filteredAuditData, setFilteredAuditData] = useState([]);

    useEffect(() => {
        fetchAuditContent();
    }, []);

    function fetchAuditContent() {
        const jwt = localStorage.getItem("jwt");
        fetch(process.env.REACT_APP_API_URL + `/report/audit`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setAuditData(jsonData);
                if (jsonData) {
                    const sortedData = jsonData.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
                    console.log(sortedData);
                    setFilteredAuditData(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const [sortedAuditsTimeOrder, setSortsortedAuditsTimeOrder] = useState('desc');
    const sortAuditsByDate = () => {
        if (filteredAuditData) {
            let sortedAudits = [...filteredAuditData];
            if (sortedAuditsTimeOrder === 'asc') {
                sortedAudits.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
            } else {
                sortedAudits.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
            }
            setFilteredAuditData(sortedAudits);
        }

    };
    useEffect(() => {
        sortAuditsByDate();
    }, [sortedAuditsTimeOrder]);

    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target;
        setFiltersByAuditType(prevFilters => ({
            ...prevFilters,
            [value]: checked
        }));
    };

    const [filtersByAuditType, setFiltersByAuditType] = useState({
        "SFPIREPORT": true,
        "MMRREPORT": true,
    });

    const filterItems = (searchTerm) => {
        if (auditData) {
            let filtered = auditData;

            filtered = filtered.filter(item => filtersByAuditType[item.auditTarget]);

            if (searchTerm) {
                filtered = filtered.filter(report => {
                    const fullName = `${report.user.firstname.toLowerCase()} ${report.user.lastname.toLowerCase()}`;
                    return fullName.includes(searchTerm.toLowerCase());
                });
            }

            setFilteredAuditData(filtered);
        } else {
            setFilteredAuditData([]);
        }
    };

    const handleSearchChange = (event) => {
        filterItems(event.target.value);
    };

    useEffect(() => {
        filterItems();
    }, [filtersByAuditType]);

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = filteredAuditData ? filteredAuditData.slice(indexOfFirstItem, indexOfLastItem) : null;

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };

    return (
        <div className="mb-200">
            <Navigation/>
            <div className="gov-container">
                <div className="gov-container__content">
                    <div className="gov-title--beta">Přehled vyžádaných exportů dat:</div>
                    <div className="gov-flexbox search-row-width">
                        <GovFormInput onGov-input={handleSearchChange}
                                      placeholder="Zadejte jméno nebo příjmení osoby" size="m"
                                      variant="secondary">
                            <GovIcon type="basic" name="search" slot="right-icon"></GovIcon>
                        </GovFormInput>
                    </div>
                    <GovSpacer size="m"></GovSpacer>
                    {filteredAuditData ? (
                        <table cellSpacing="0" cellPadding="0">
                            <thead>
                            <tr>
                                <th>Osoba</th>
                                <th>
                                    <div className="gov-flexbox--justify-start">
                                        <GovDropdown position="left" className="min-height-400">
                                            <GovButton className="padding-0" variant="primary" size="xs"
                                                       type="base"
                                                       wcag-label="What exactly happens when you click?"
                                                       right-icon="basic/chevron-down">Typ reportu
                                                <GovIcon type="basic" name="chevron-down"
                                                         slot="right-icon"></GovIcon>
                                            </GovButton>
                                            <ul slot="list">
                                                <li>
                                                    <GovButton variant="secondary" size="xs" type="base"
                                                               wcag-label="Home">
                                                        <GovFormCheckbox size="xs" value="SFPIREPORT"
                                                                         onGov-change={handleCheckboxChange}
                                                                         checked={filtersByAuditType["SFPIREPORT"]}>
                                                            <GovFormLabel size="xs" slot="label">Report SFPI
                                                            </GovFormLabel>
                                                        </GovFormCheckbox>
                                                    </GovButton>
                                                </li>
                                                <li>
                                                    <GovButton variant="secondary" size="xs" type="base"
                                                               wcag-label="Home">
                                                        <GovFormCheckbox size="xs" value="MMRREPORT"
                                                                         onGov-change={handleCheckboxChange}
                                                                         checked={filtersByAuditType["MMRREPORT"]}>
                                                            <GovFormLabel size="xs" slot="label">Report MMR
                                                            </GovFormLabel>
                                                        </GovFormCheckbox>
                                                    </GovButton>
                                                </li>
                                            </ul>
                                        </GovDropdown>
                                    </div>
                                </th>
                                <th>Datum a čas vyžádání reportu
                                    {sortedAuditsTimeOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortsortedAuditsTimeOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortsortedAuditsTimeOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }</th>
                                <th>Regionální centrum</th>
                            </tr>

                            </thead>
                            <tbody>
                            {currentItems.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.user.firstname + " " + row.user.lastname}</td>
                                    <td>{auditReportToText(row.auditTarget)}</td>
                                    <td>{convertIsoToCustomFormatForDocuments(row.createdOn)}</td>
                                    <td>{row.allRc ? "Všechna regionální centra" :
                                        row.regionalCenter ? row.regionalCenter.name : "Report MMR"}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                     animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                    )}
                </div>
                <div className="pagination-container">
                    {filteredAuditData ? (
                        <GovPagination onGov-page={handlePageChange} variant="primary" size="s" type="button"
                                       current={currentPage} total={filteredAuditData.length} page-size={itemsPerPage}
                                       max-pages="4" wcag-label="Main paging" wcag-page-label="Page"></GovPagination>
                    ) : (
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                     animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                    )}


                </div>
            </div>
        </div>
    );
}
